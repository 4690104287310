import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="waiting-room"
export default class extends Controller {
  static targets = ["currentTimer"];

  connect() {
    this.ticker = 60;

    this.poller = setInterval(() => {
      this.ticker -= 1;
      this.currentTimerTarget.innerText = this.ticker.toString();

      if (this.ticker === 0) {
        clearInterval(this.poller);
        location.reload();
      }
    }, 1000);
  }
}
